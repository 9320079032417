@import "./tailwind.css";

@layer components { /* stylelint-disable-line at-rule-no-unknown */
	.link {
		@apply text-blue-500 hover:text-blue-400 focus:text-blue-400 hover:underline focus:outline-white;
	}

	.focusable {
		@apply focus:outline-none focus:ring focus:border-blue-800;
	}
}

/* could not get background to work in Tailwind */
main {
	background-image: url("/assets/images/bg-blue.png");
	background-repeat: repeat;
}
